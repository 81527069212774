<template>
  <b-modal 
    size="lg"
    id="preconsulting-modal"
    hide-header
    hide-footer
    v-if="!!preConsultation"
  >
    <div
      class="
        header
        d-flex
        flex-row
        align-items-center
        justify-content-between
        pb-3
      "
      style="border-bottom: solid 1px #d9dff2; margin-bottom: 40px"
    >
      <p class="title mb-0">Pré-consulta</p>
      <Close
        style="width: 24px"
        @click="$bvModal.hide('preconsulting-modal')"
      />
    </div>

    <div class="p-3">
      <section class="pre-consultation-header">
        <div class="pre-consultation-container">
          <p class="pre-consultation-subtitle">
            Pré-consulta 
            <span v-if="preConsultation.professional_id">realizada por</span>
          </p>
        <p class="pre-consultation-author">
          {{ preConsultation.professional ? preConsultation.professional.name : '' }}
        </p>
      </div>
      <div class="pre-consultation-container">
          <p class="pre-consultation-subtitle">
            Data
          </p>
        <p class="pre-consultation-author">
          {{ moment(preConsultation.updated_at).format('DD/MM/YYYY')}}
        </p>
      </div>
      </section>
      

      <hr />
      <section class="records-container">
        <v-medical-records :records="preConsultation.medical_records" noFilter />
      </section>
      
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import Close from '@/assets/icons/close.svg'
import History from '@/components/MedicalRecords/Modal/HistoryModal.vue'
export default {
  components: { 
    Close, 
    'v-medical-records': History 
  },
  computed: {
    ...mapGetters('attendance', ['preConsultation']),
  },
}
</script>
<style lang="scss">
#preconsulting-modal {
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #0c1d59;
  }

  .pre-consultation-header {
    display: flex;
    flex-direction: row;
    div:nth-child(1) {
      margin-right: 80px;
    }
  }

  .pre-consultation-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    
    .pre-consultation-subtitle {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 134%;
      letter-spacing: -0.01em;
      color: #0e3de2;
      opacity: 0.9;
    }

    .pre-consultation-author {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 132%;
      color: #0c1d59;
    }
  }

  .records-container {
    width: 100%;
    height: 500px; 
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .subtitle {
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    color: #0c1d59;
    margin-bottom: 24px;
  }

  .ar-labels {
    display: grid;
    grid-template-columns: repeat(4, 117px);
    margin-bottom: 2px;
    padding-left: 62px;

    div {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  .input-group {

    .input-group-text {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      font-weight: 600;
      width: 62px;
      color: #525c7a;
    }

    .form-control {
      border-radius: 0 8px 8px 0 !important;

      select {
        border: none !important;
      }
    }
  }

  .form-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .form-ar-prepend-one {
    .input-group-text {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0;
      border-bottom-width: 0.5px;
      font-weight: 600;
      width: 62px;
      color: #525c7a;
    }

    .form-control {
      text-align: center;
      max-width: 117px;
      border-radius: 0 !important;
      border-bottom-width: 0.5px;

      &:last-child {
        border-radius: 0 8px 0 0 !important;
      }
    }
  }

  .form-ar-prepend-two {
    margin-bottom: 58px;

    .input-group-text {
      border-radius: 0 0 0 8px !important;
      border-top-width: 0.5px;
      font-weight: 600;
      color: #525c7a;
      width: 62px;
    }

    .form-control {
      text-align: center;
      max-width: 117px;
      border-top-width: 0.5px;
      border-radius: 0 !important;

      &:last-child {
        border-radius: 0 0 8px 0 !important;
      }
    }
  }

  .anamnese-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-bottom: 56px;
  }

  .avsc-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    margin-bottom: 56px;
  }

  .avcc-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    margin-bottom: 56px;
  }

  .tonometria-form {
    display: grid;
    grid-template-columns: 3fr 1fr 2fr 2fr;
    grid-gap: 16px;
    margin-bottom: 56px;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 16px;
  }

  ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
  }

  ::-webkit-scrollbar-track {
      background: var(--neutral-000);
      border-radius: 0 0 8px 0;
  }

  ::-webkit-scrollbar-thumb {
      background-color: var(--neutral-300);
      border-radius: 100px;
      border: 3px solid var(--neutral-000);
  }

  ::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
      background: var(--neutral-200);
  }
}
</style>